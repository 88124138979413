<template>
  <v-card-text>
    <v-expansion-panels>
      <v-expansion-panel v-for="{ _id: pId, area, supervisors } in areas" :key="area">
        <v-expansion-panel-header>{{ area }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mb-4">
            <v-col v-for="{ _id: sId, pidm, name, email } in supervisors" :key="pidm" :cols="12" sm="6" md="4" lg="3">
              <v-card elevation="3">
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ name }}</v-list-item-title>
                      <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon color="error" @click="removeSupervisor(pId, sId)">
                        <v-icon>fal fa-times-circle</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
          <v-dialog v-model="addSupervisorDialog" width="400">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="success">
                <v-icon left>fal fa-plus-circle</v-icon>
                Add Supervisor
              </v-btn>
            </template>
            <v-card>
              <v-card-title>Add Supervisor</v-card-title>
              <v-card-text>
                <directory-search v-model="personSearch" :person-filter="['Faculty','Staff']" clearable />
              </v-card-text>
              <v-card-actions>
                <v-btn color="success" text @click="addSupervisor(pId)">Add Supervisor</v-btn>
                <v-spacer />
                <v-btn text @click="addSupervisorDialog = false">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog v-model="addAreaDialog" width="400">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" color="success" class="mt-4">
          <v-icon left>fal fa-plus-circle</v-icon>
          Add Area
        </v-btn>
      </template>
      <v-card>
        <v-card-title>Add Area</v-card-title>
        <v-card-text>
          <v-text-field v-model="addAreaName" label="Area Name" outlined />
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" text @click="addArea">Add Area</v-btn>
          <v-spacer />
          <v-btn text @click="addAreaDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card-text>
</template>
<script>
import { onMounted, ref } from '@vue/composition-api'

export default {
  setup (props, { root }) {
    const areas = ref([])
    async function loadAreas () {
      const { data } = await root.$feathers.service('serve/area').find({ query: { $populate: 'supervisors' } })
      areas.value = data
    }

    const addAreaDialog = ref(false)
    const addAreaName = ref('')
    async function addArea () {
      await root.$feathers.service('serve/area').create({ area: addAreaName.value })
      await loadAreas()
      addAreaName.value = ''
      addAreaDialog.value = false
    }

    const addSupervisorDialog = ref(false)
    const personSearch = ref('')
    async function addSupervisor (_id) {
      const { value: dirId, text: name } = personSearch.value
      const rec = await root.$feathers.service('directory/people').get(dirId)
      await root.$feathers.service('serve/area').patch(_id, { $push: { supervisors: { pidm: rec.pidm, name, email: rec.email } } })
      await loadAreas()
      personSearch.value = null
      addSupervisorDialog.value = false
    }
    async function removeSupervisor (areaId, supervisor) {
      const { supervisors: s } = await root.$feathers.service('serve/area').get(areaId)
      const supervisors = s.filter((id) => id !== supervisor)
      await root.$feathers.service('serve/area').patch(areaId, { supervisors })
      loadAreas()
    }

    async function updateArea (item, area) {
      console.log(item)
      console.log(area)
    }

    onMounted(() => {
      loadAreas()
    })

    return {
      areas,
      loadAreas,
      addAreaDialog,
      addAreaName,
      addArea,
      addSupervisorDialog,
      personSearch,
      addSupervisor,
      removeSupervisor,
      updateArea
    }
  }
}
</script>
