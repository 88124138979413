var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',[_c('v-expansion-panels',_vm._l((_vm.areas),function(ref){
var pId = ref._id;
var area = ref.area;
var supervisors = ref.supervisors;
return _c('v-expansion-panel',{key:area},[_c('v-expansion-panel-header',[_vm._v(_vm._s(area))]),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"mb-4"},_vm._l((supervisors),function(ref){
var sId = ref._id;
var pidm = ref.pidm;
var name = ref.name;
var email = ref.email;
return _c('v-col',{key:pidm,attrs:{"cols":12,"sm":"6","md":"4","lg":"3"}},[_c('v-card',{attrs:{"elevation":"3"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(email))])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.removeSupervisor(pId, sId)}}},[_c('v-icon',[_vm._v("fal fa-times-circle")])],1)],1)],1)],1)],1)],1)}),1),_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"success"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-plus-circle")]),_vm._v(" Add Supervisor ")],1)]}}],null,true),model:{value:(_vm.addSupervisorDialog),callback:function ($$v) {_vm.addSupervisorDialog=$$v},expression:"addSupervisorDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Add Supervisor")]),_c('v-card-text',[_c('directory-search',{attrs:{"person-filter":['Faculty','Staff'],"clearable":""},model:{value:(_vm.personSearch),callback:function ($$v) {_vm.personSearch=$$v},expression:"personSearch"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){return _vm.addSupervisor(pId)}}},[_vm._v("Add Supervisor")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.addSupervisorDialog = false}}},[_vm._v("Cancel")])],1)],1)],1)],1)],1)}),1),_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-4",attrs:{"color":"success"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-plus-circle")]),_vm._v(" Add Area ")],1)]}}]),model:{value:(_vm.addAreaDialog),callback:function ($$v) {_vm.addAreaDialog=$$v},expression:"addAreaDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Add Area")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Area Name","outlined":""},model:{value:(_vm.addAreaName),callback:function ($$v) {_vm.addAreaName=$$v},expression:"addAreaName"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":_vm.addArea}},[_vm._v("Add Area")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.addAreaDialog = false}}},[_vm._v("Cancel")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }